import { graphql, useStaticQuery } from 'gatsby';
import { document } from 'browser-monads';
import * as React from 'react';
import { ThemeProvider } from '../../../../components/src/components/Layout/ThemeProvider/ThemeProvider';
import Header from '../Header/Header';
import { Image } from '../Image/Image';
import { Main } from '../Main/Main';
import { WaveContainer } from '../WaveContainer/WaveContainer';

const APP_URL = process.env['GATSBY_APP_URL'];

const getLoginUrl = () => {
  if (document.location && document.location.href.includes('test.fakturuj.to')) {
    return 'https://app.test.fakturuj.to/';
  } else {
    return APP_URL;
  }
}

const Layout: React.FC = ({ children }) => {
  require('./../../../static/styles/main.css');

  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);

  return (
    <ThemeProvider
      render={() => (
        <WaveContainer>
          <Header
            siteTitle={data.site.siteMetadata.title}
            loginUrl={getLoginUrl()}
          />
          <Main>
            <div>
              {children}
            </div>
            <Image/>
          </Main>
        </WaveContainer>
      )}
    />
  );
};

export default Layout;
