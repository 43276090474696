const API_URL = process.env['GATSBY_API_URL'];
const USER_EVENTS_URL = `${API_URL}/user-events`;

export const makeCreateUserEventRequest = async (tags: string[], userEmail: string) =>
  await fetch(`${USER_EVENTS_URL}/`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({ tags, userEmail }),
  });
