import * as classnames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  font-size: 3.2rem;
  font-family: 'Roboto-Bold';

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    font-size: 4.8rem;
  }
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 5.6rem;
  }
`;

const P = styled.p`
  font-family: 'Roboto';
  font-size: 1.4rem;
  margin-top: 0.8rem;
  color: ${props => props.theme.colors.primary13};
  
  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    margin-top: 1.4rem;  
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 2.3rem;
    margin-top: 2rem;
  }
`;


const Div = styled.div`
  margin-top: 6.2rem;

  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    margin-top: 7rem;
  }
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-top: 15.6rem;
  }
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
     margin-top: 22.1rem;
  }
`;

const Hero: React.FC = () => {

  const tailwindClassNames = ['text-primary2', 'font-bold', 'tracking-normal'];

  return (
    <Div>
      <H1 className={classnames(tailwindClassNames)}>
        Jesteś&nbsp;Programistą?
      </H1>
      <P className="mt-3">Mamy program do faktur dla Ciebie!</P>
    </Div>
  );
};

export default Hero;
