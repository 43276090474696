import { Container as Checkbox } from 'components/src/components/Elements/Checkbox/Checkbox-styled';
import * as React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { getEmail } from '../../utils/cookies';
import { Anchor } from '../Anchor/Anchor';
import { SocialButton } from '../Buttons/SocialButton';

type FormValues = Record<'email' | 'consent-1' | 'consent-2', string>;

const FORM_LABEL_SUBMIT = 'Załóż konto';
const FORM_LABEL_EMAIL_ERROR = 'Podaj poprawny adres email';
const FORM_LABEL_CONSENT_ERROR = 'Konieczne jest zaakceptowanie obu zgód';

const EmailInput = styled.input.attrs({ type: 'text', placeholder: 'example@email.com' })`
  border: .1rem solid ${props => props.theme.colors.primary1};
  border-radius: .8rem;
  font-size: 1.4rem;
  padding: 1rem;
  transform: translate(.5rem, 0);
  height: 4rem;
  width: 21rem;
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    width: 27.6rem;
    height: 4.8rem;
    font-size: 1.6rem;
    padding: 1.5rem 1.2rem 1.3rem;
    font-family: 'Quicksand-Medium', sans-serif;
  }
  
  ::placeholder {
    color: ${props => props.theme.colors.primary5};
    opacity: .48;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 1.7rem;
  
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
      margin-top: 2.2rem;
    }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.primary14};
  font-size: 1rem;

    @media (${props => props.theme.deviceBreakpoints.laptop}) {
      font-size: 1.2rem;
    }
`;

const FormContainer = styled.form`
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
      margin-top: 9rem;
    }
`;

const SubmitInput = styled.input.attrs({ type: 'submit' })`
  color: #fff;
  background-color: ${props => props.theme.colors.primary1};
  border: 1px solid ${props => props.theme.colors.primary1};
  border-top-right-radius: .8rem;  
  border-bottom-right-radius: .8rem;
  font-size: 1.4rem;
  padding: 1rem 2rem 1.1rem;
  height: 4rem;
  width: 11.4rem;
  line-height: 1;
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    height: 4.8rem;
    width: 17.2rem;
    font-size: 1.6rem;
    padding: 1.4rem 4rem;
    font-family: 'Quicksand-Medium', sans-serif;
  }
`;

type Props = {
  onSubmit: (values: FormValues) => void;
};

const showError = (fieldError?: FieldError) => {
  if (!fieldError) {
    return null;
  }
  return (
    <p className="ml-3 text-error">{fieldError.message}</p>
  );
};

const Form: React.FC<Props> = ({ onSubmit }) => {
  const regulationsUrl = 'https://fakturuj.to/regulamin/';
  const privacyPolicyUrl = 'https://fakturuj.to/polityka-prywatnosci/';

  const STATEMENT = <>Oświadczam że zapoznałam/ zapoznałem się z treścią </>;
  const REGULATIONS = <Anchor href={regulationsUrl} target="_blank" css={`font-weight: bold`}>Regulaminu</Anchor>;
  const PRIVACY_POLITICS = <Anchor href={privacyPolicyUrl} target="_blank" css={`font-weight: bold`}>Polityki
    Prywatności i Cookies</Anchor>;

  const { handleSubmit, register, errors } = useForm<FormValues>();

  return (
    <FormContainer
      onSubmit={handleSubmit(onSubmit)}
      className="mt-16 desktop:mt-40"
    >
      <div
        className="flex"
        css={`transform: translate(-.5rem,0)`}
      >
        <EmailInput
          name="email"
          defaultValue={getEmail() || ''}
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: FORM_LABEL_EMAIL_ERROR,
            },
          })}
        />
        <SubmitInput
          type="submit"
          name="submit"
          value={FORM_LABEL_SUBMIT}
        />
      </div>
      {showError(errors.email)}
      <SocialButton css={`display: none`}/>
      <Section>
        <Label>
          <Checkbox
            css={`margin-right: 0.8rem`}
            type="checkbox"
            name="consent-1"
            ref={register({
              required: FORM_LABEL_CONSENT_ERROR,
            })}
          />
          {STATEMENT}{REGULATIONS}
        </Label>
        <Label css={`margin-top: 0.6rem`}>
          <Checkbox
            css={`margin-right: 0.8rem`}
            type="checkbox"
            name="consent-2"
            ref={register({
              required: FORM_LABEL_CONSENT_ERROR,
            })}
          />
          {STATEMENT}{PRIVACY_POLITICS}
        </Label>
        {showError(errors['consent-2']) || showError(errors['consent-1'])}
      </Section>
    </FormContainer>
  );
};

export default Form;
