import styled from 'styled-components';

export const Container = styled.input`
  transform: scale(.8);
  width: 2rem;
  height: 2rem;

  vertical-align: middle;
  appearance: none;
  border-radius: .4rem;
  box-sizing: border-box;
  border: 0.1rem solid ${({ theme }) => theme.colors.secondary7};
  position: relative;
  transition: all 0.2s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary12};
    outline: none;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary12};
  }

  &:active {
    transform: scale(0.8);
  }

  &:checked, &:indeterminate {
    border: none;
    background: ${({ theme }) => theme.colors.primary3};
    transition: transform .3s cubic-bezier(.2, .3, 0, 1), box-shadow .3s cubic-bezier(.2, .3, 0, 1), border-color 0s;

    &:focus {
      border-color: ${({ theme }) => theme.colors.secondary12};
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.secondary12};
    }

    &:before, &:after {
      background: ${({ theme }) => theme.colors.white};
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.disabled};
    box-shadow: inset 0 0 0 1rem ${({ theme }) => theme.colors.disabled};

    &:not(:checked) {
      box-shadow: inset 0 0 0 1rem ${({ theme }) => theme.colors.secondary7};

      &:before, &:after {
        display: none;
      }
    }
  }

  &:before, &:after {
    content: '';
    background: transparent;
    height: 1.7rem;
    width: 1.9rem;
    position: absolute;
    transform: translate(0.5728rem, 0.86rem) rotate(44.91deg) scale(0.3411, 0.1);
    transform-origin: 0 0;
    transition: transform 0.3s cubic-bezier(0.2, 0.3, 0, 1), opacity 0.3s cubic-bezier(0.2, 0.3, 0, 1);
    top: 0.1rem;
    left: -0.1rem;
  }

  &:after {
    transform: translate(1.7494rem, 0.65rem) rotate(135.22deg) scale(0.5612, 0.1);
  }

  &:not(:checked):before {
    transform: translate(.505rem, .455rem) rotate(44.91deg) scale(.1, .1);
    opacity: 0;
  }

  &:not(:checked):after {
    transform: translate(.575rem, .525rem) rotate(135.22deg) scale(.1, .1);
  }

  &:indeterminate:before {
    transform: translate(.505rem, .455rem) rotate(44.91deg) scale(.1, .1);
    opacity: 0;
  }

  &:indeterminate:after {
    transform: translate(1.55rem, 0.95rem) rotate(180deg) scale(0.4, 0.1);
  }
`;

export const LabeledContainer = styled.label`
  font-family: ${({ theme }) => theme.fonts.quicksandMedium}, sans-serif;
  color: ${({ theme }) => theme.colors.secondary9};
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: capitalize;
  vertical-align: middle;
  padding: 0 0.5rem;

  input {
    margin-right: 1rem;
  }
`;
