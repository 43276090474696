import * as React from 'react';
import styled from 'styled-components';
import { Anchor } from '../Anchor/Anchor';
import { GitHubLogInButton } from './SocialAuthorizationButtons/GitHubLogInButton';

const Container = styled.div`
  margin-top: 1.4rem;

  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    margin-top: 1.7rem;
  }
  
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-top: 2.3rem;
  }
`;

export const SocialButton: React.FC<React.HTMLProps<HTMLAnchorElement>> = props => (
  <Container>
    <Anchor
      {...props}
      className={`${props.className ? props.className : ''} hover:no-underline`}
    >
      <GitHubLogInButton/>
    </Anchor>
  </Container>
);
