import { Cookies } from 'react-cookie';

const COOKIE_DOMAIN = process.env['GATSBY_COOKIE_DOMAIN'];
const ENV = process.env['NODE_ENV'];

const landingEmailCookieName = 'landing-email';
const userSetupPlanIdCookieName = 'user-setup-planId';
const customerSegmentCookieName = 'customer-segment';

export const getEmail = () => {
  const cookies = new Cookies();

  return cookies.get(landingEmailCookieName);
};

export const setEmail = (value: string) => {
  const cookies = new Cookies();

  return cookies.set(landingEmailCookieName, value, {
    secure: ENV !== 'development',
    domain: COOKIE_DOMAIN,
  });
};

export const setUserSetupPlanId = (value: string) => {
  const cookies = new Cookies();

  return cookies.set(userSetupPlanIdCookieName, value, {
    secure: ENV !== 'development',
    domain: COOKIE_DOMAIN,
  });
};

export const setCustomerSegment = (value: string) => {
  const cookies = new Cookies();

  return cookies.set(customerSegmentCookieName, value, {
    secure: ENV !== 'development',
    domain: COOKIE_DOMAIN,
  });
};
