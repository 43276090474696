import { navigate } from '@reach/router';
import * as React from 'react';
import { makeCreateUserEventRequest } from '../api/makeCreateUserEventRequest';
import Form from '../components/Form/Form';
import Hero from '../components/Hero/Hero';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import { setCustomerSegment, setEmail, setUserSetupPlanId } from '../utils/cookies';

const PLAN_ID = process.env['GATSBY_PLAN_ID'];
const CUSTOMER_SEGMENT = process.env['CUSTOMER_SEGMENT'];

const IndexPage = () => (
  <Layout>
    <Seo title="FAKTURUJ.TO proste!"/>
    <Hero/>
    <Form
      onSubmit={async (values) => {
        console.log(values);
        const value = values.email;
        await makeCreateUserEventRequest([
          'submit-email',
          ...(values['consent-1'] ? ['consent-1'] : []),
          ...(values['consent-2'] ? ['consent-2'] : []),
        ], value);
        setEmail(value);
        setUserSetupPlanId(PLAN_ID);
        setCustomerSegment(CUSTOMER_SEGMENT);
        await navigate('/onboarding');
      }}
    />
  </Layout>
);

export default IndexPage;
