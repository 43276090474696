import { StyledGitHubIcon } from 'components/src/components/Icons/IconCoponents/GitHubIcon';
import * as React from 'react';
import styled from 'styled-components';

const Span = styled.span`
    font-size: 1.2rem;
    padding: 0.4rem 0.8rem 0.4rem 0;
    font-family: 'Quicksand-Medium', sans-serif;
    color: ${props => props.theme.colors.primary12};
`;

const Promote = styled.span`
    font-family: 'Quicksand-Bold', sans-serif;
    font-size: 1.4rem;
`;

const GITHUB_LABEL = <>Załóż konto przez <Promote>GitHub</Promote></>;

export const GitHubLogInButton: React.FC = () => (
  <Span>
    <StyledGitHubIcon/>
    {GITHUB_LABEL}
  </Span>
);
