/* tslint:disable:max-line-length */
import * as React from 'react';
import styled from 'styled-components';

export const GitHubIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       viewBox="0 0 48 48" {...props}
  >
    <path d="M40,0H8C3.582,0,0,3.582,0,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8V8C48,3.582,44.418,0,40,0z M8,47
      c-3.86,0-7-3.141-7-7V8c0-3.86,3.14-7,7-7h32c3.86,0,7,3.14,7,7v32c0,3.859-3.14,7-7,7H8z"
    />
    <path d="M39.74,20.131C37.52,13.483,31.297,9,24.288,9c-8.997,0-16.291,7.294-16.29,16.292
      c0,7.013,4.489,13.239,11.142,15.455c0.815,0.149,1.112-0.353,1.112-0.785c0-0.387-0.014-1.411-0.022-2.771
      c-4.531,0.985-5.487-2.183-5.487-2.183c-0.299-0.985-0.941-1.83-1.809-2.383c-1.479-1.011,0.112-0.99,0.112-0.99
      c1.046,0.144,1.967,0.763,2.495,1.678c0.921,1.679,3.03,2.293,4.709,1.372c0.011-0.006,0.022-0.012,0.032-0.018
      c0.074-0.826,0.441-1.598,1.034-2.178c-3.617-0.411-7.42-1.808-7.42-8.051c-0.023-1.618,0.577-3.184,1.677-4.371
      c-0.498-1.405-0.44-2.947,0.16-4.311c0,0,1.367-0.438,4.479,1.67c2.67-0.731,5.486-0.731,8.156,0c3.11-2.108,4.475-1.67,4.475-1.67
      c0.601,1.364,0.659,2.906,0.163,4.311c1.1,1.187,1.7,2.753,1.674,4.371c0,6.258-3.809,7.635-7.438,8.038
      c0.786,0.799,1.189,1.899,1.106,3.017c0,2.178-0.02,3.935-0.02,4.469c0,0.435,0.294,0.942,1.12,0.783
      C37.982,37.895,42.59,28.666,39.74,20.131z"
    />
  </svg>
);

export const StyledGitHubIcon = styled(GitHubIcon)`
  fill: ${props => props.theme.colors.primary15};
  display: inline;

  margin-right: 0.8rem;
  width: 4.8rem;
  height: 4.8rem;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
      margin-right: 1.4rem;
    }
`;
